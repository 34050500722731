
import RadioBox from "@/components/RadioBox.vue";
import CustomInput from "@/components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import {
  addServer,
  deleteServer,
  getServer,
  ServerResponse,
  updateServer,
} from "@/services/exploreService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ServerVisibility",
  components: { RadioBox, CustomInput, CustomButton },
  data() {
    return {
      error: null as string | null,
      visibility: null as number | null,
      response: null as ServerResponse | null,
      description: "",
      updating: false,
    };
  },
  computed: {
    showSave(): any {
      if (!this.response) return false;
      if (this.description !== this.response?.description || "") return true;
      return false;
    },
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.response = null;
      getServer(this.serverID)
        .then((server) => {
          this.visibility = 1;
          this.response = server;
          this.description = server.description || "";
        })
        .catch((err) => {
          this.visibility = null;
          if (!err.response) return;
          this.visibility = 0;
        });
    },
    deleteServer() {
      if (this.updating) return;
      this.error = null;
      this.updating = true;

      deleteServer(this.serverID)
        .then(() => {
          this.getDetails();
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = this.$t("could-not-connect-to-server").toString();
            return;
          }
          const json = await err.response.json();
          this.error = json.message;
        })
        .finally(() => {
          this.updating = false;
        });
    },
    addServer() {
      if (this.updating) return;
      this.error = null;
      this.updating = true;

      addServer(this.serverID, this.description)
        .then(() => {
          this.getDetails();
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = this.$t("could-not-connect-to-server").toString();
            return;
          }
          const json = await err.response.json();
          this.error = json.message;
        })
        .finally(() => {
          this.updating = false;
        });
    },
    update() {
      if (this.updating) return;
      this.error = null;
      this.updating = true;
      updateServer(this.serverID, this.description)
        .then(() => {
          this.getDetails();
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = this.$t("could-not-connect-to-server").toString();
            return;
          }
          const json = await err.response.json();
          this.error = json.message;
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
});
