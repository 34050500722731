
import { Tab, TabsModule } from "@/store/modules/tabs";
import { PropType } from "vue";
import AvatarImage from "@/components/AvatarImage.vue";
import { UsersModule } from "@/store/modules/users";
import { ServersModule } from "@/store/modules/servers";
import { MeModule } from "@/store/modules/me";
import UserStatusTemplate from "@/components/UserStatusTemplate.vue";
import Server from "@/interfaces/Server";
import User from "@/interfaces/User";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { NotificationsModule } from "@/store/modules/notifications";

import { defineComponent } from "vue";
import { PopoutsModule } from "@/store/modules/popouts";
export default defineComponent({
  components: { AvatarImage, UserStatusTemplate },
  props: {
    selected: Boolean,
    tab: {
      type: Object as PropType<Tab>,
      required: true,
    },
  },
  mounted() {
    this.getTabElement().addEventListener("auxclick", this.onMiddleClick);
  },
  beforeUnmount() {
    this.getTabElement().removeEventListener("auxclick", this.onMiddleClick);
  },
  methods: {
    onRightClick(event: MouseEvent) {
      event.preventDefault();
      PopoutsModule.ShowPopout({
        id: "context",
        component: "TabContextMenu",
        key: this.tab.name + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          tab: this.tab,
        },
      });
    },
    getTabElement() {
      return (this.$refs?.tab as any).$el as HTMLElement;
    },
    onMiddleClick(event: MouseEvent) {
      if (event.button === 1) {
        event.preventDefault();
        this.closeTab();
      }
    },
    openTab() {
      TabsModule.openTab({ ...this.tab, opened: true });
    },
    closeTab() {
      if (!this.tab.path) return;
      TabsModule.closeTabByPath(this.tab.path);
    },
  },
  computed: {
    seed(): string | undefined {
      return this.tab.server_id || this.tab.user_id;
    },
    user(): User | undefined {
      if (!this.tab.user_id) return undefined;
      return UsersModule.users[this.tab.user_id];
    },
    server(): Server | undefined {
      if (!this.tab.server_id) return undefined;
      return ServersModule.servers[this.tab.server_id];
    },
    title(): string {
      if (this.user && !this.isSavedNotes) {
        return this.user.username;
      }
      return this.tab.name;
    },
    avatar(): string | undefined {
      return this.user?.avatar || this.server?.avatar;
    },
    isSavedNotes(): boolean {
      return this.tab.user_id === MeModule.user.id;
    },
    serverNotificationExists(): any {
      if (!this.server) return false;
      if (!this.tab.channel_id) return false;
      return LastSeenServerChannelsModule.serverChannelNotification(
        this.tab.channel_id
      );
    },
    dmNotificationExists(): any {
      if (!this.user) return false;
      if (!this.tab.user_id) return false;
      return NotificationsModule.notificationByUserID(this.tab.user_id)?.count;
    },
    showAlert(): any {
      return this.serverNotificationExists || this.dmNotificationExists;
    },
  },
});
