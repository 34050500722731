
import RadioBox from "@/components/RadioBox.vue";
import { MutedServersModule } from "@/store/modules/mutedServers";
import { muteServer } from "@/services/serverService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ServerSettingsArea",
  components: { RadioBox },
  computed: {
    serverID(): any {
      return this.$route.params.server_id;
    },
    serverNotificationOption: {
      get(): number {
        return MutedServersModule.mutedServers?.[this.serverID]?.type || 0;
      },
      set(val: number) {
        MutedServersModule.SetMutedServer({
          serverID: this.serverID,
          type: val,
        });
      },
    },
  },
  methods: {
    onRadioIndexChange(index: number) {
      muteServer(this.serverID, index);
    },
  },
});
