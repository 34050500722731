
import Channel from "@/interfaces/Channel";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChannelTemplate",
  props: {
    channel: {
      type: Object as PropType<Channel>,
      required: true,
    },
  },
});
