
import Invite from "@/interfaces/Invite";
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
export default defineComponent({
  name: "InviteTemplate",
  components: { AvatarImage },
  props: {
    invite: {
      type: Object as PropType<Invite>,
      required: true,
    },
  },
  data() {
    return {
      prefixURL: process.env.VUE_APP_MAIN_APP_URL,
    };
  },
  methods: {
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.invite.creator.id },
      });
    },
    copyLink() {
      toClipboard(`${this.prefixURL}i/${this.invite.invite_code}`);
      alert(this.$t("generic.copied"));
    },
  },
});
