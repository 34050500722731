
import CustomInput from "@/components/CustomInput.vue";
import UsersList from "./UsersList.vue";
import UserDetails from "./UserDetails.vue";
import { ServerMembersModule } from "@/store/modules/serverMembers";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ServerSettingsArea",
  components: { CustomInput, UsersList, UserDetails },
  data() {
    return {
      searchValue: "",
      selectedServerMember: null as any,
    };
  },
  computed: {
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  methods: {
    userClicked(id: string) {
      this.selectedServerMember = ServerMembersModule.serverMember(
        this.serverID,
        id
      );
    },
  },
});
