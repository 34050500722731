
import Draggable from "vuedraggable";

import Channel from "@/interfaces/Channel";
import { PropType } from "vue";
import { defineComponent } from "vue";
import ChannelTemplate from "./ChannelTemplate.vue";
import { ChannelsModule } from "@/store/modules/channels";
import { ServersModule } from "@/store/modules/servers";
import { updateServerChannelPosition } from "@/services/channelService";
export default defineComponent({
  name: "CategoryTemplate",
  components: { ChannelTemplate, Draggable },
  emits: ["click"],
  data() {
    return {
      categoryChannels: [],
    };
  },
  props: {
    category: {
      type: Object as PropType<Channel>,
      required: true,
    },
  },
  computed: {
    channels: {
      get(): Channel[] {
        return ChannelsModule.sortedServerChannels(this.serverID);
      },
      set(channels: Channel[]) {
        ServersModule.UpdateServer({
          server_id: this.serverID,
          channel_position: channels.map((c) => c.channelId),
        });
      },
    },
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  methods: {
    onEnd(event: any) {
      let category: null | { id: string| null; channelId: string } = null;

      if (event.from !== event.to) {
        category = {
          channelId: event.item.id.split("-")[1],
          id: event.to.id.split("-")[1],
        };
      }
      const channelIDs = this.channels.map((s) => s.channelId);
      updateServerChannelPosition(this.serverID, channelIDs, category);
    },
  },
});
