
import { PropType } from "vue";

import { defineComponent } from "vue";
export default defineComponent({
  emits: ["change", "update:modelValue"],
  props: {
    items: Array as PropType<string[]>,
    modelValue: Number,
  },
  methods: {
    clicked(index: number) {
      this.$emit("update:modelValue", index);
      this.$emit("change", index);
    },
  },
});
