
import AvatarImage from "@/components/AvatarImage.vue";
import { ServerRolesModule } from "@/store/modules/serverRoles";
import ServerMember from "@/interfaces/ServerMember";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { UsersModule } from "@/store/modules/users";
export default defineComponent({
  name: "RightDrawer",
  components: { AvatarImage },
  props: {
    serverMember: {
      type: Object as PropType<ServerMember>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    member(): any {
      return UsersModule.users[this.serverMember.id];
    },
    roles(): any {
      return ServerRolesModule.bulkRolesById(
        this.serverMember.server_id,
        this.serverMember.roleIdArr
      );
    },
    firstRoleColor(): any {
      if (this.roles[0]) {
        return this.roles[0].color;
      }
      if (this.defaultRole && this.defaultRole.color) {
        return this.defaultRole.color;
      }
      return undefined;
    },
    defaultRole(): any {
      return ServerRolesModule.defaultServerRole(this.serverMember.server_id);
    },
  },
  methods: {
    rightClickEvent(event: MouseEvent) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "UserContextMenu",
        key: this.member.id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          id: this.member.id,
        },
      });
    },
  },
});
