
import Draggable from "vuedraggable";
import { ServersModule } from "@/store/modules/servers";
import CustomButton from "@/components/CustomButton.vue";
import ChannelTemplate from "./ChannelTemplate.vue";
import CategoryTemplate from "./CategoryTemplate.vue";
import { ChannelsModule } from "@/store/modules/channels";
import ContextMenu from "@/components/ContextMenu.vue";
import SelectedChannelPage from "./SelectedChannelPage.vue";
import {
  createServerChannel,
  updateServerChannelPosition,
} from "@/services/channelService";
import Channel from "@/interfaces/Channel";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ManageChannels",
  components: {
    CustomButton,
    ChannelTemplate,
    CategoryTemplate,
    ContextMenu,
    SelectedChannelPage,
    Draggable,
  },
  data() {
    return {
      showContext: false,
      selectedChannelID: null as string | null,
      createRequestSent: false,
      contextItems: [
        {
          name: this.$t("server-settings.manage-channels.text-channel"),
          icon: "textsms",
          id: "text-channel",
        },
        {
          name: this.$t("server-settings.manage-channels.category-channel"),
          icon: "segment",
          id: "category-channel",
        },
        {
          name: this.$t("server-settings.manage-channels.html-channel"),
          icon: "code",
          disabled: true,
          id: "html-channel",
        },
      ],
    };
  },
  computed: {
    server(): any {
      return ServersModule.servers[this.serverID];
    },
    channels: {
      get(): Channel[] {
        return ChannelsModule.sortedServerChannels(this.serverID);
      },
      set(channels: Channel[]) {
        ServersModule.UpdateServer({
          server_id: this.serverID,
          channel_position: channels.map((c) => c.channelId),
        });
      },
    },
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  mounted() {
    const { id } = this.$route.params as { [key: string]: string };
    this.selectedChannelID = id || null;
    id && this.$router.replace({ params: { id: null as any } });
  },
  methods: {
    onContextClick(item) {
      if (item.id === "text-channel") {
        this.createChannel(1);
      }
      if (item.id === "category-channel") {
        this.createChannel(2);
      }
    },
    outsideClick(event: any) {
      if (event.target.classList.contains("button")) return;
      this.showContext = false;
    },
    onDragEnd(event: any) {
      let category: null | { id: string; channelId: string } = null;
      if (event.from !== event.to) {
        category = {
          id: event.to.id.split("-")[1],
          channelId: this.channels[event.oldIndex].channelId,
        };
      }
      const channelIDs = this.channels.map((s) => s.channelId);
      updateServerChannelPosition(this.serverID, channelIDs, category);
    },
    createChannel(type = 1) {
      this.showContext = false;
      if (this.createRequestSent) return;
      const name = type === 1 ? "New Channel" : "New Category";
      this.createRequestSent = true;
      createServerChannel(this.serverID, name, type)
        .then((json) => {
          ChannelsModule.AddChannel(json.channel);
          this.selectedChannelID = json.channel.channelId;
        })
        .finally(() => {
          this.createRequestSent = false;
        });
    },
  },
});
