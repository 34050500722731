
import ServerRole from "@/interfaces/ServerRole";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "RoleTemplate",
  props: {
    role: {
      type: Object as PropType<ServerRole & { canModify: boolean }>,
      required: true,
    },
  },
});
