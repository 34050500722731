
import { ServersModule } from "@/store/modules/servers";

import LoadingScreen from "@/components/LoadingScreen.vue";
import UserTemplate from "./UserTemplate.vue";
import User from "@/interfaces/User";
import { getBannedUsers } from "@/services/serverService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ServerSettingsArea",
  components: { LoadingScreen, UserTemplate },
  data() {
    return {
      bans: null as User[] | null,
    };
  },
  computed: {
    server(): any {
      return ServersModule.servers[this.serverID];
    },
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  mounted() {
    getBannedUsers(this.serverID).then((data) => {
      this.bans = data.reverse().map((d) => d.user);
    });
  },
  methods: {
    userUnbanned(index: number) {
      if (!this.bans) return;
      this.bans.splice(index, 1);
    },
  },
});
