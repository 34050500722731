
import { ChannelsModule } from "@/store/modules/channels";
import { DrawersModule } from "@/store/modules/drawers";
import { PopoutsModule } from "@/store/modules/popouts";
import { voiceChannelModule } from "@/store/modules/voiceChannels";
import Tabs from "@/components/HeaderTabs.vue";

import { joinCall, leaveCall } from "@/services/voiceService";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  components: { Tabs },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    isServerChannel(): any {
      return this.$route.params.server_id;
    },
    DMChannel(): any {
      return ChannelsModule.getDMChannel(
        this.$route.params.channel_id as string
      );
    },
    DMUser(): any {
      return this.DMChannel?.recipients?.[0];
    },
    channelId(): string {
      return this.$route.params.channel_id as string;
    },
  },
  methods: {
    onCallClicked() {
      if (this.channelId === voiceChannelModule.joinedChannelId) return;
      PopoutsModule.ShowPopout({
        id: "call-ip-leak-warning",
        component: "generic-popout",
        data: {
          callback: () => this.joinCall(),
          title: "P2P Calling Notice",
          description:
            "Because of the nature of P2P, your IP will be able to be seen by other participants on the call.",
        },
      });
    },
    async joinCall() {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await leaveCall().catch(() => {});
      voiceChannelModule.join({
        channelId: this.channelId,
      });
      joinCall(this.channelId);
    },
    toggleLeftDrawer() {
      DrawersModule.SetLeftDrawer(true);
    },
    toggleRightDrawer() {
      DrawersModule.SetRightDrawer(!DrawersModule.rightDrawer);
    },
    showProfile() {
      if (!this.DMUser?.id) return;
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.DMUser.id },
      });
    },
  },
});
