
import Tab from "./HeaderTabTemplate.vue";
import { TabsModule } from "@/store/modules/tabs";
import Draggable from "vuedraggable";

import { defineComponent } from "vue";
export default defineComponent({
  name: "HeaderTabs",
  components: { Tab, Draggable },
  mounted() {
    setTimeout(() => {
      this.scrollToTab();
    }, 1000);
  },
  methods: {
    scrollToTab() {
      const scroller = (this.$refs.scroller as any)?.$el as HTMLElement;
      if (!scroller) return;
      const index = this.currentTabIndex;
      if (index < 0) return;
      this.$nextTick(() => {
        scroller.children[index].scrollIntoView();
      })
    },
  },
  watch: {
    currentTabIndex: {
      immediate: true,
      handler() {
        this.scrollToTab();
      },
    },
  },
  computed: {
    tabs: {
      get(): any {
        return TabsModule.tabs;
      },
      set(tabs: any) {
        TabsModule.updateTabs(tabs);
      },
    },
    currentTabIndex(): number {
      return this.tabs.findIndex((tab) => tab.path === this.$route.path);
    },
  },
});
