
import CustomInput from "@/components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import CheckBox from "@/components/CheckBox.vue";
import PickerArea from "@/components/picker-area/PickerArea.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import {
  deleteServerChannel,
  updateServerChannel,
} from "@/services/channelService";
import { ChannelsModule } from "@/store/modules/channels";
import { ServersModule } from "@/store/modules/servers";
import { MeModule } from "@/store/modules/me";
import Channel from "@/interfaces/Channel";
import twemoji from "@twemoji/api";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ManageChannels",
  components: {
    CustomInput,
    CustomButton,
    CheckBox,
    ContextMenu,
    PickerArea,
  },
  props: {
    channelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteConfirm: false,
      deleteRequestSent: false,
      requestSent: false,
      channelName: "" as string | undefined,
      channelIcon: null as null | string,
      rateLimit: 0,
      error: null as string | null,
      showIconContext: false,
      showEmojiPicker: false,
      sendMessagePermission: false,
    };
  },
  computed: {
    channelIconHTML(): any {
      if (!this.channelIcon) return null;
      const isCustom =
        this.channelIcon?.startsWith("g_") ||
        this.channelIcon?.startsWith("c_");
      const isGif = this.channelIcon?.startsWith("g_");
      const customEmojiID = this.channelIcon?.split("_")[1];

      const image = new Image();
      image.classList.add("emoji");

      if (isCustom) {
        image.src = `${
          process.env.VUE_APP_NERTIVIA_CDN
        }emojis/${customEmojiID}.${isGif ? "gif" : "png"}`;
      } else {
        image.src =
          process.env.VUE_APP_TWEMOJI_LOCATION +
          twemoji.convert.toCodePoint(this.channelIcon).replace("-fe0f", "") +
          ".png";
      }
      return image.outerHTML;
    },
    channel(): Channel | undefined {
      return ChannelsModule.channels[this.channelId];
    },
    server(): any {
      return ServersModule.servers[this.channel?.server_id || ""];
    },
    showSaveButton(): any {
      const { channelName, sendMessagePermission, rateLimit, channelIcon } =
        this.changed;
      return channelName || sendMessagePermission || rateLimit || channelIcon;
    },
    changed(): any {
      let currentPermission = this.channel?.permissions?.send_message;
      if (this.channel?.permissions?.send_message === undefined) {
        currentPermission = true;
      }
      const rateLimit = this.rateLimit !== (this.channel?.rateLimit || 0);
      const channelName = this.channelName !== this.channel?.name;

      const channelIcon = (this.channel?.icon || null) !== this.channelIcon;

      const sendMessagePermission =
        this.sendMessagePermission !== currentPermission || false;

      return { channelName, sendMessagePermission, rateLimit, channelIcon };
    },
    connected(): any {
      return MeModule.connected;
    },
  },
  watch: {
    connected: {
      handler: "isConnected",
    },
    channel: {
      handler: "channelChange",
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.rateLimit = this.channel?.rateLimit || 0;
      this.channelName = this.channel?.name;
      this.channelIcon = this.channel?.icon || null;
      if (this.channel?.permissions?.send_message === undefined) {
        return (this.sendMessagePermission = true);
      }
      this.sendMessagePermission =
        this.channel?.permissions?.send_message || false;
    },
    onEmojiContextClick(item: { id: string }) {
      switch (item.id) {
        case "emoji":
          this.showEmojiPicker = true;
          this.showIconContext = false;
          break;
        case "default":
          this.channelIcon = null;
          this.showIconContext = false;
          break;
        default:
          break;
      }
    },
    emojiClicked(emoji: any) {
      const { unicode, id, gif } = emoji;
      this.showEmojiPicker = false;
      if (unicode) {
        this.channelIcon = unicode;
        return;
      }
      this.channelIcon = `${gif ? "g" : "c"}_${id}`;
    },
    isConnected(val: boolean) {
      if (val) {
        this.reset();
      }
    },
    channelChange(channel: Channel) {
      if (!channel) {
        this.$emit("close");
      }
    },
    deleteChannel() {
      if (!this.channel?.server_id) return;
      if (this.deleteRequestSent) return;
      if (!this.deleteConfirm) {
        this.deleteConfirm = true;
        return;
      }
      this.deleteRequestSent = true;

      deleteServerChannel(this.channelId, this.channel.server_id)
        .then(() => {
          this.$emit("close");
          ChannelsModule.RemoveChannel(this.channelId);
        })
        .finally(() => {
          this.deleteRequestSent = false;
          this.deleteConfirm = false;
        });
    },
    update() {
      if (this.requestSent) return;
      this.requestSent = true;
      if (!this.channel?.server_id) return;
      this.error = null;
      updateServerChannel(this.channel.channelId, this.channel.server_id, {
        name: this.channelName,
        permissions: { send_message: this.sendMessagePermission },
        rateLimit: parseInt(this.rateLimit as any),
        icon: this.channelIcon || null,
      })
        .then((json) => {
          ChannelsModule.updateChannel({
            channelId: json.channelId,
            update: json,
          });
          this.reset();
        })
        .catch(async (err) => {
          if (!err.response) {
            return (this.error = this.$t(
              "could-not-connect-to-server"
            ).toString());
          }
          const json = await err.response.json();
          const { errors, message } = json;
          if (message) return (this.error = message);
          for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            this.error = error.msg;
          }
        })
        .finally(() => (this.requestSent = false));
    },
  },
});
