
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import User from "@/interfaces/User";
import { unbanMember } from "@/services/serverService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "UserTemplate",
  components: { AvatarImage, CustomButton },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      requestSent: false,
    };
  },
  computed: {
    serverID(): any {
      return this.$route.params.server_id;
    },
  },
  methods: {
    unban() {
      if (this.requestSent) return;
      this.requestSent = true;
      unbanMember(this.serverID, this.user.id).then(() => {
        this.$emit("deleted");
      });
    },
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.user.id },
      });
    },
  },
});
